// @ts-ignore
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { removeEmptyFilterQueries } from "../../helpers/general";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import { useAuth } from "../useAuth";
import { useCookie } from "../useCookie";
import { useToast } from "../useToast";
import { BookingStatus } from "../../helpers/enums";

const generateBaseUrl = (isAdmin: boolean) => {
  // return isAdmin ? "booking/filter" : "booking/ours";
  return "booking/filter";
};

const generateQueryParams = (isAdmin: boolean, query: string) => {
  const baseEndpoint = generateBaseUrl(isAdmin);
  const endpoint = `${baseEndpoint}?${query}`;
  return endpoint;
};

const mergeFilters = (
  search: string | null,
  bookingStatus: string | null,
  place: string | null,
  filters: any[],
  isPassed: boolean = false,
  specialOffer: string | null,
) => {
  const filterQuery: { $and: any[] } = { $and: [] };

  const statusQuery = {
    status: {
      label: {
        $eq: bookingStatus
      }
    }
  };
  const userQuery = {
    user: {
      fullName: {
        $containsi: search
      }
      // email: {
      //   $contains: searchQuery
      // }
    }
  };

  const placeQuery = {
    place: {
      id: {
        $eq: place
      }
    }
  };

  const specialOfferQuery = {
    special: {
      id: {
        $eq: specialOffer
      }
    }
  };

  if (bookingStatus) {
    filterQuery.$and.push(statusQuery, { isUsed: { $eq: isPassed } });
  }
  if (search) {
    filterQuery.$and.push(userQuery);
  }
  if (place) {
    filterQuery.$and.push(placeQuery);
  }
  if (specialOffer) {
    filterQuery.$and.push(specialOfferQuery);
  }
  if (filters.length > 0) {
    filterQuery.$and.push(...filters);
  }
  return filterQuery;
};

export const useBookingQuery = () => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [placeId, setPlaceId] = useState<any>("");
  const [filters, setFilters] = useState<any>({});
  // const [bookingStatus, setBookingStatus] = useState<any>("");

  const { isAdmin } = useAuth();
  // const search = useDebounce(searchQuery, 2000);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams, placeId, filters]);

  const isPassed = searchParams.get("status") === BookingStatus.PASSED;
  const isConfirmed = searchParams.get("status") === BookingStatus.CONFIRMED;

  const bookingStatus = isPassed
    ? BookingStatus.CONFIRMED
    : searchParams.get("status");
  const search = searchParams.get("search");
  const place = searchParams.get("place");
  const sort = searchParams.get("sort");

  const statusQuery = {
    status: {
      label: {
        $eq: bookingStatus
      }
    }
  };
  const userQuery = {
    user: {
      fullName: {
        $containsi: search
      }
      // email: {
      //   $contains: searchQuery
      // }
    }
  };

  const placeQuery = {
    place: {
      id: {
        $eq: place
      }
    }
  };


  const q = useMemo(() => {
    return {
      filters: mergeFilters(search, bookingStatus, place, filters,  isPassed ,searchParams.get("specialOffer"),),
      pagination: {
        page: currentPage,
        limit: 10
      },
      sort: sort ?? "createdAt:desc"
    };
  }, [
    bookingStatus,
    currentPage,
    place,
    placeQuery,
    search,
    searchParams.size,
    sort,
    filters,
    mergeFilters
  ]);

  const setBookingFilters = (filters: any) => {
    setFilters(filters);
  };

  // const setSort = (sort: string) => {

  // }

  const endPoint = useMemo(() => {
    const query = removeEmptyFilterQueries(qs.stringify(q, { encode: false }));
    return generateQueryParams(isAdmin, query);
  }, [q, isAdmin]);
  // console.log(endPoint);

  const queryDependencies = ["getAllBookings", currentPage, endPoint];

  const { data, isLoading, isPreviousData } = useQuery(
    queryDependencies,
    () => {
      return apiService.MakeGetRequest(endPoint, token);
    },
    { keepPreviousData: true }
  );
  const deleteBooking = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`booking/${id}`, token);
  });

  // const bookings: Booking[] = isPassed
  //   ? data?.items.filter((booking: Booking) => booking?.isUsed)
  //   : isConfirmed
  //   ? data?.items.filter((booking: Booking) => !booking?.isUsed)
  //   : data?.items;

  return {
    bookings: data?.items,
    totalItems: data?.meta.totalPages,
    totalCount: data?.meta.totalItems,
    fetchingBookings: isLoading,
    isPreviousData,
    deleteBooking(id: string) {
      deleteBooking.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.bookingDeleted);
          queryClient.invalidateQueries(["getAllBookings", currentPage]);
        }
      });
    },
    mutating: deleteBooking.isLoading,
    currentPage,
    setCurrentPage,
    handleSearch: (query: string) => setSearchQuery(query),
    search: searchQuery,
    placeId,
    setPlaceId,
    setBookingStatus: () => {},
    setFilters: setBookingFilters
    // setSort: setSort,
  };
};
