import React, { useReducer } from "react";
import { useDriverQuery } from "../../../hooks/drivers/useDriverQuery";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../../context/bulkDeleteReducer";
import AdminRoute from "../../auth/RestrictedRoute";
import BoxWrapper from "../../../components/BoxWrapper";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { CSpinner } from "@coreui/react";
import { general } from "../../../locales/general";
import { colors } from "../../../config/theme";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { pluralize } from "../../../helpers/general";
import Pagination from "../../../components/Pagination";
import DriversTable from "../DriverTable";
import RenderTable from "../../../components/RenderTable";
import { SidebarIcon } from "../../../components/icons/SidebarIcons";

export default function PendingDrivers() {
  const {
    data,
    isLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    searchHandler,
    removeDriver,
    mutating,
    status,
    statusHandler
  } = useDriverQuery("IN_REVIEW");

  const [DriverToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    DriverToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      removeDriver(id);
      DriverToBeDeleted.ids = [];
    });
  };

  return (
    <AdminRoute>
      <section>
        <h5 className="fs-4 fw-bold mb-2">Chauffeurs en attente</h5>
        <BoxWrapper>
          <div className="d-flex  justify-content-between align-items-baseline before-table">
            <div className="d-flex align-items-center gap-4 p-3 delete-add">
              <button
                disabled={mutating || DriverToBeDeleted?.ids.length === 0}
                onClick={handleBulkDelete}
                className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
              >
                {!mutating ? (
                  <>
                    <DeleteIcon fill={colors.primary} /> Supprimer
                  </>
                ) : (
                  <>
                    <CSpinner size="sm" /> {general.fr.wait}
                  </>
                )}
              </button>

              <button
                type="button"
                onClick={() => statusHandler("IN_REVIEW")}
                className={` rounded-sm py-2 px-3  d-flex align-items-center gap-2 justify-content-center shadow-primary border-0 text-primary
                  ${
               status === "IN_REVIEW" 
               ?  "bg-gradient-primary text-white"
               : "bg-grey text-black"
             }
               `}
              >
                En attente
              </button>
              <button
                type="button"
                onClick={() => statusHandler("APPROVED")}
                className={` rounded-sm py-2 px-3  d-flex align-items-center gap-2 justify-content-center shadow-primary border-0 text-primary
                   ${
                status === "APPROVED" 
                ?  "bg-gradient-primary text-white"
                : "bg-grey text-black"
              }
                `}

              >
                Acceptée
              </button>
            </div>
            <div className="search-form">
              <div className=" input-field">
                <MagnifyingGlassIcon color="gray" width={20} />
                <input
                  type="text"
                  className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                  placeholder="Rechercher"
                  onChange={e => searchHandler(e.target.value)}
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
              style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
            >
              <SidebarIcon.RequestDriverSVG className="fill-white" />
              <p className="fs-5 fw-bold m-0  text-white text-pre">
                {data?.meta?.totalItems}{" "}
                {pluralize("chauffeur", data?.meta?.totalItems)}
              </p>
            </div>
          </div>

          <RenderTable
            loading={isLoading}
            render={() =>
              data?.items?.length > 0 ? (
                <DriversTable
                  drivers={data?.items as Driver[]}
                  DriversTableToBeDeleted={dispatch}
                />
              ) : (
                <p className="text-center fs-5 my-2">{general.fr.noData}</p>
              )
            }
          />
        </BoxWrapper>
        <Pagination
          currentPage={currentPage}
          onPageChange={page => {
            setCurrentPage(page);
          }}
          siblingCount={1}
          totalCount={data?.meta?.totalPages}
          pageSize={pageSize}
        />
      </section>
    </AdminRoute>
  );
}
