import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup";
import { Input } from "../../components/inputs/Input";
import { apiConfig } from "../../config/apiConfig";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";

type VehicleInfoFormProps = {
  driverProfile?: DriverProfile;
  driverId?: string;
};

const validations = yup.object().shape({
  type: yup.string().required("Ce champ est obligatoire"),
  immatriculation: yup.string().required("Ce champ est obligatoire"),
  model: yup.string().required("Ce champ est obligatoire"),
  fabricationYear: yup.string().required("Ce champ est obligatoire"),
  taxiNumber: yup.string().required("Ce champ est obligatoire"),
  numberOfPlaces: yup.string().required("Ce champ est obligatoire")
});

type vehicleFormInput = {
  type: string;
  immatriculation: string;
  model: string;
  fabricationYear: string;
  taxiNumber: string;
  numberOfPlaces: string;
};

export default function VehicleInfoForm(props: VehicleInfoFormProps) {
  const { driverProfile, driverId } = props;
  const defaultValues = useMemo(() => {
    return {
      type: driverProfile?.vehicle?.type || "",
      immatriculation: driverProfile?.vehicle?.immatriculation || "",
      model: driverProfile?.vehicle?.model || "",
      fabricationYear: driverProfile?.vehicle?.fabricationYear || "",
      taxiNumber: driverProfile?.vehicle?.taxiNumber || "",
      numberOfPlaces: driverProfile?.vehicle?.numberOfPlaces
        ? String(driverProfile?.vehicle?.numberOfPlaces)
        : ""
    };
  }, [driverProfile]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<vehicleFormInput>({
    resolver: yupResolver(validations)
  });

  const { toastError, toastSuccess } = useToast();
  const { token } = useCookie("vToken");
  const updateVehicleForm = useMutation((data: vehicleFormInput) => {
    const endpoint = apiConfig.users.drivers.complete_profile(driverId!);
    return apiService.MakePutRequest(endpoint, data, token);
  });

  useEffect(() => {
    Object.entries(defaultValues).forEach(([key, value]) => {
      setValue(key as any, value);
    });
  }, [defaultValues]);

  const onSubmit = async (data: vehicleFormInput) => {
    try {
      await updateVehicleForm.mutateAsync(data);
      toastSuccess(general.fr.message.profileUpdated);
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  const typeError = errors?.type?.message;

  return (
    <div className="py-4 d-flex flex-column align-items-center bg-white px-2 mx-auto ">
      <form onSubmit={handleSubmit(onSubmit)} className="col-md-10 mt-2">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="type" className="d-block text-sm">
              Type de véhicule
            </label>
            <Controller
              name="type"
              control={control}
              defaultValue={driverProfile?.vehicle?.type || ""}
              render={({ field }) => (
                <select
                  className="custom-input w-100 mt-1"
                  //   placeholder="Type de véhicule"
                  id="type"
                  {...field}
                >
                  <option value="">Type de véhicule</option>
                  <option value="red_taxi">Petite taxi</option>
                </select>
              )}
            />
            {typeError && <p className="text-red">{typeError}</p>}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="immatriculation" className="d-block text-sm">
              Immatriculation
            </label>
            <Input
              type="text"
              className="custom-input w-100 mt-1"
              name="immatriculation"
              placeholder="Immatriculation"
              id="immatriculation"
              defaultValue={driverProfile?.vehicle?.immatriculation || ""}
              control={control}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="model" className="d-block text-sm">
              Modèle
            </label>
            <Input
              type="text"
              className="custom-input w-100 mt-1"
              name="model"
              placeholder="Modèle"
              id="model"
              defaultValue={driverProfile?.vehicle?.model || ""}
              control={control}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="fabricationYear" className="d-block text-sm">
              Année de fabrication
            </label>
            <Input
              type="text"
              className="custom-input w-100 mt-1"
              name="fabricationYear"
              placeholder="Année de fabrication"
              id="fabricationYear"
              defaultValue={driverProfile?.vehicle?.fabricationYear || ""}
              control={control}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="taxiNumber" className="d-block text-sm">
              Numéro de Taxi
            </label>
            <Input
              type="text"
              className="custom-input w-100 mt-1"
              name="taxiNumber"
              placeholder="Numéro de Taxi"
              id="taxiNumber"
              defaultValue={driverProfile?.vehicle?.taxiNumber || ""}
              control={control}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="numberOfPlaces" className="d-block text-sm">
              Nombre de places
            </label>
            <Input
              type="text"
              className="custom-input w-100 mt-1"
              name="numberOfPlaces"
              placeholder="Nombre de places"
              id="numberOfPlaces"
              defaultValue={driverProfile?.vehicle?.numberOfPlaces || ""}
              control={control}
            />
          </div>
        </div>
        <button
          disabled={updateVehicleForm.isLoading}
          className="btn btn-md btn-danger rounded-sm mt-2 text-white"
        >
          Mettre &agrave; jour les informations
        </button>
      </form>
    </div>
  );
}
