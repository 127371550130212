import { useEffect, useMemo, useReducer } from "react";
import { Input } from "../../components/inputs/Input";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useTransactions } from "../../hooks/useTransactions";
import RenderTable from "../../components/RenderTable";
import TransactionsTable from "../transactions/TransactionsTable";
import { general } from "../../locales/general";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import Pagination from "../../components/Pagination";
import { useMutation, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { CSpinner } from "@coreui/react";
import { apiConfig } from "../../config/apiConfig";

interface Props {
  data?: Driver;
}

const validations = yup.object().shape({
  balance: yup
    .number()
    .required("Ce champ est obligatoire")
    .min(0)
});

type FormInput = {
  balance: number;
};

export default function Balance(props: Props) {
  const { data } = props;
  const { control, handleSubmit, setValue } = useForm<FormInput>({
    resolver: yupResolver(validations),
    defaultValues: { balance: 0 }
  });

  const queryClient = useQueryClient();

  const [transactionsToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const {
    transactions,
    isfetchingTransaction: isLoading,
    queries,
    addQuery
  } = useTransactions();

  useEffect(() => {
    addQuery("userId", data?.id);
  }, [data?.id]);

  const { toastError, toastSuccess } = useToast();
  const { token } = useCookie("vToken");

  const endpoint = apiConfig.transactions.topup(data?.id!);

  const updateProfile = useMutation((payload: FormInput) => {
    return apiService.MakePutRequest(
      endpoint,
      { amount: payload.balance },
      token
    );
  });

  const updateProfileHandler = async (data: FormInput) => {
    try {
      await updateProfile.mutateAsync(data);
      queryClient.invalidateQueries();
      toastSuccess("Solde mis à jour");
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div>
      <section>
        <form
          onSubmit={handleSubmit(updateProfileHandler)}
          className="py-3 d-flex align-items-center justify-content-center bg-white px-2 mx-auto gap-5"
        >
          <div className="row">
            <label htmlFor="" className="d-block text-sm">
              Recharge
            </label>
            <Input
              type="number"
              className="custom-input w-100 mt-1"
              name="balance"
              placeholder="solde"
              id="solde"
              defaultValue={data?.balance}
              control={control}
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="btn btn-danger shadow-primary rounded-sm py-2 px-3 text-white"
              disabled={updateProfile.isLoading}
            >
              {updateProfile.isLoading ? (
                <div className="text-center">
                  <CSpinner size="sm" />
                </div>
              ) : (
                "Recharge"
              )}
            </button>
          </div>
        </form>
      </section>

      <div className="mt-3 pt-2">
        {/* ///////////////////////////////////////////////////////////////////////// */}

        <RenderTable
          loading={isLoading}
          render={() =>
            transactions?.items && transactions?.items?.length > 0 ? (
              <TransactionsTable
                transactions={transactions?.items ?? []}
                onTransactionSelect={dispatch}
                showuser={false}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
        <Pagination
          currentPage={queries.page}
          onPageChange={page => {
            addQuery("page", page);
          }}
          siblingCount={1}
          totalCount={transactions?.meta?.totalPages ?? 1}
          pageSize={10}
        />
      </div>
    </div>
  );
}
