import React, { useEffect, useMemo, useReducer, useState } from "react";
import Pagination from "../../components/Pagination";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import BoxWrapper from "../../components/BoxWrapper";
import CategoriesTable from "./CategoryTable";
import { usePaginatedContent } from "../../hooks/usePaginatedContent";
import { general } from "../../locales/general";
import { CModal, CSpinner } from "@coreui/react";
import RenderTable from "../../components/RenderTable";
import { useCategoryQuery } from "../../hooks/category/useCategoryQuery";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import NewCategory from "./NewCategory";
import AdminRoute from "../auth/RestrictedRoute";

const PAGE_SIZE = 10;

export default function Categories() {
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const {
    categories,
    fetchingCategories,
    deleteCategory,
    mutating
  } = useCategoryQuery();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const filteredCategories = useMemo(() => {
    const filteredCategories = categories?.filter((category: any) =>
      category.label?.toLowerCase()?.includes(searchQuery)
    );
    return filteredCategories;
  }, [categories, searchQuery]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredCategories?.length / PAGE_SIZE));
  }, [searchQuery, filteredCategories]);

  const paginatedContent = usePaginatedContent(
    currentPage,
    PAGE_SIZE,
    filteredCategories
  );

  const [categoriesToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    categoriesToBeDeleted.ids.forEach(id => {
      if (!id) return;
      deleteCategory(id);
      categoriesToBeDeleted.ids = [];
    });
  };

  const CategoriesSvg = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.53988 2H7.91988C9.32988 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32988 10.53 7.91988 10.53H4.53988C3.13988 10.53 1.99988 9.39 1.99988 7.97V4.561C1.99988 3.15 3.13988 2 4.53988 2ZM4.53988 13.4697H7.91988C9.32988 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32988 21.9997 7.91988 21.9997H4.53988C3.13988 21.9997 1.99988 20.8497 1.99988 19.4397V16.0307C1.99988 14.6107 3.13988 13.4697 4.53988 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z"
        />
      </svg>
    );
  };

  return (
    <AdminRoute>
      <h5 className="fs-4 fw-bold mb-2">Catégories</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-between align-items-baseline before-table">
          <div className="d-flex align-items-center gap-2 p-3 delete-add">
            <button
              onClick={() => setVisible(!visible)}
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
            >
              + Ajouter une catégorie
            </button>
            <CModal
              size="lg"
              alignment="center"
              visible={visible}
              onClose={() => setVisible(false)}
            >
              <NewCategory
                onAccept={() => {}}
                onClose={() => setVisible(false)}
              />
            </CModal>

            <button
              disabled={mutating || categoriesToBeDeleted?.ids.length === 0}
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
            >
              {!mutating ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="search-form">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e => setSearchQuery(e?.target?.value?.toLowerCase())}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
            style={{ borderTopRightRadius: "8px" }}
          >
            <CategoriesSvg />
            <p className="fs-5 fw-bold m-0 text-white text-pre">
              {categories?.length}{" "}
              {categories?.length > 1 ? "Catégories" : "Catégorie"}
            </p>
          </div>
        </div>
        <RenderTable
          loading={fetchingCategories}
          render={() =>
            paginatedContent.length > 0 ? (
              <CategoriesTable
                categories={paginatedContent as Category[]}
                categoriesToBeDeletedList={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={1}
        totalCount={totalPages}
        pageSize={PAGE_SIZE}
      />
    </AdminRoute>
  );
}
