import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

export type DocViewerProps = {
  open: boolean;
  index: number;
  close: () => void;
  docs: any[];
};

const DocViewer: React.FC<DocViewerProps> = ({ open, close, index, docs }) => {
  return (
    <Lightbox
      noScroll={{
        disabled: true
      }}
      index={index}
      open={open}
      carousel={{ finite: true }}
      controller={{
        closeOnBackdropClick: true,
        preventDefaultWheelX: true,
        preventDefaultWheelY: true
      }}
      plugins={[Zoom]}
      zoom={{ maxZoomPixelRatio: 6, doubleClickMaxStops: 3 }}
      close={close}
      styles={{
        navigationNext: {display: "none"},
        navigationPrev: {display: "none"},
        container: {
          backgroundColor: "rgba(0, 0, 0, .8)"
        },
        toolbar: { backgroundColor: "rgba(0, 0, 0, 0)" }
      }}
      slides={docs
        // ?.filter(doc => doc?.src)
        .map(doc => ({
          src: doc?.src as string
        }))}
    />
  );
};

export default DocViewer;
