import { useEffect, useState } from "react";
import {
  TransactionStatus,
  TransactionType,
  TranscationPaymentMethods
} from "../../helpers/enums";
import { useQuery } from "react-query";
import { apiConfig } from "../../config/apiConfig";
import { useCookie } from "../useCookie";
import apiService from "../../service/apiService";

interface Query {
  query: string;
  page: number;
  status?: keyof typeof TransactionStatus;
  paymentMethod?: keyof typeof TranscationPaymentMethods;
  type?: keyof typeof TransactionType;
  userId?: string;
}

type TransactionsResponse = {
  items: Transaction[];
  meta: PaginationMeta;
};
const defaultQuery = {
  query: "",
  page: 1,
  status: undefined,
  paymentMethod: undefined,
  type: undefined,
  userId: undefined
};

export function useTransactions(defaultQueries?: Partial<Query>) {
  const [queries, setQueries] = useState<Query>(defaultQuery);

  useEffect(() => {
    if (defaultQueries) {
      setQueries({ ...defaultQuery, ...defaultQueries });
    }
  }, [defaultQueries]);

  const addQuery = <K extends keyof typeof queries>(
    key: K,
    value: typeof queries[K]
  ) => {
    const page = key === "page" ? (value as number) : 1;
    setQueries(prev => ({ ...prev, [key]: value, page }));
  };

  const endpoint = apiConfig.transactions.all({ ...queries });
  const { token } = useCookie("vToken");
  const { query, page, status, paymentMethod, userId } = queries;
  const deps = [
    "transactions",
    query,
    page,
    status,
    paymentMethod,
    userId,
    endpoint
  ];

  const { data, isLoading } = useQuery<TransactionsResponse>(deps, () => {
    return apiService.MakeGetRequest(endpoint, token);
  });

  return {
    queries,
    addQuery,
    transactions: data,
    isfetchingTransaction: isLoading
  };
}
