import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { general } from "../../locales/general";
import { useToast } from "../useToast";
import { useDebounce } from "../useDebounce";
import { useState } from "react";
import { toQuery } from "../../helpers/general";
import { DriverProfileStatus } from "../../helpers/enums";

type Status = keyof typeof DriverProfileStatus;

export const useDriverQuery = (defaultStatus?: Status) => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();

  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState<Status | undefined>(defaultStatus);
  const debouncedQuery = useDebounce(searchQuery, 2000);
  const [currentPage, setCurrentPage] = useState(1);

  const PAGE_SIZE = 10;
  const fetchDrivers = async (
    query: string = "",
    page: number,
    status?: string
  ) => {
    const endpoint = `users/drivers${toQuery({
      page,
      query,
      limit: PAGE_SIZE,
      status: status ?? ""
    })}`;
    return apiService.MakeGetRequest(endpoint, token);
  };

  const queryKey = ["getAllDrivers", debouncedQuery, currentPage, status];

  const { data, isLoading } = useQuery(queryKey, () =>
    fetchDrivers(debouncedQuery, currentPage, status?.toLowerCase())
  );

  const deleteDriver = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`users/${id}`, token);
  });

  return {
    data,
    isLoading,
    currentPage,
    setCurrentPage,
    pageSize: PAGE_SIZE,
    searchHandler: (query: string) => setSearchQuery(query),
    status,
    statusHandler: (status: Status) => setStatus(status),
    removeDriver(id: string) {
      deleteDriver.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.userDeleted);
          queryClient.invalidateQueries("getAllDrivers");
        }
      });
    },
    mutating: deleteDriver.isLoading
  };
};
