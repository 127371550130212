import { CSpinner } from "@coreui/react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { apiConfig } from "../../../config/apiConfig";
import { RideStatus } from "../../../helpers/enums";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";

interface Props {
  selectedTaxi: string;
}

type RideResponse = {
  items: Ride[];
  meta: PaginationMeta;
};

const getCarType = (type: string) => {
  let cartype = "Taxi";

  switch (type) {
    case "red_taxi":
      cartype = "Taxi";
      break;
  }

  return cartype;
};

export default function TaxiDetails(props: Props) {
  const { selectedTaxi } = props;
  const { token } = useCookie("vToken");

  const { data, isLoading } = useQuery<Driver>(
    ["getDriver", selectedTaxi],
    () => {
      return apiService.MakeGetRequest(`users/driver/${selectedTaxi}`, token);
    },
    { enabled: !!selectedTaxi }
  );
  const { data: rides, isLoading: loading } = useQuery<RideResponse>(
    ["getUserRides", selectedTaxi],
    () => {
      return apiService.MakeGetRequest(
        apiConfig.users.rides(selectedTaxi, { status: RideStatus.ON_GOING }),
        token
      );
    },
    { enabled: !!selectedTaxi }
  );

  const LoadingUserInfoContent = () => (
    <div className="d-flex align-content-center justify-content-center h-full w-full ">
      <CSpinner size="sm" color="success" />
    </div>
  );
  const ride = rides?.items?.[0];

  return (
    <div>
      {!isLoading ? (
        data ? (
          <div className="border p-2 rounded-sm">
            <div className="ride-driver-details">
              <div className="driver-details  border-bottom">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={
                      data?.profilePicture ??
                      `https://ui-avatars.com/api/?name=${data?.fullName}`
                    }
                    alt=""
                    width={30}
                    height={30}
                    style={{ borderRadius: "100%" }}
                  />

                  <div>
                    <p className="m-0 fw-semibold">{data?.fullName}</p>
                    <p className="text-sm">{data?.phone}</p>
                  </div>
                </div>
              </div>
              <div className="mt-1">
                <p className="text-sm m-0">
                  <span className="fw-semibold"> Type:</span>{" "}
                  {getCarType(data?.driverProfile?.vehicle?.type)} |{" "}
                  <span className="fw-semibold">Modèle:</span>{" "}
                  {data?.driverProfile?.vehicle?.model}
                </p>
                <p className="text-sm m-0">
                  <span className="fw-semibold"> Capacité:</span>{" "}
                  {data?.driverProfile?.vehicle?.numberOfPlaces} |{" "}
                  <span className="fw-semibold">N° de véhicule: </span>
                  {data?.driverProfile?.vehicle?.taxiNumber}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <p className="fw-semibold">
            Un problème s&apos;est produit. Les détails du véhicule n&apos;ont
            pas été obtenus
          </p>
        )
      ) : (
        <Skeleton height={60} className="rounded-sm" />
      )}

      {!loading ? (
        ride ? (
          <div className="mt-3">
            <h5 className="mb-3 text-md">En route</h5>

            <div>
              <p className="text-light mb-0 text-sm">Localisation</p>
              <p className="text-sm mb-2">{ride?.pickupLocationName}</p>
              <p className="text-light mb-0 text-sm">Destination</p>
              <p className="text-sm mb-1">{ride?.destinationLocationName}</p>
              <div className="d-flex flex-column flex-md-row align-items-md-center gap-2 justify-content-between mt-3">
                <div>
                  <p className="text-light mb-0 text-sm">Distance</p>
                  <p className="text-sm mb-1 fw-semibold">{ride?.distance} m</p>
                </div>
                <div>
                  <p className="text-light mb-0 text-sm">Prix du trajet</p>
                  <p className="text-sm mb-1 fw-semibold">{ride?.fare} MAD</p>
                </div>
              </div>
            </div>
          </div>
        ) : null
      ) : (
        <div className="mt-3">
          <Skeleton height={20} count={4} />
        </div>
      )}
    </div>
  );
}
