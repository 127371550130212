import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { FormSelectAPI } from "../../components/FormReactSelect";
import { Input } from "../../components/inputs/Input";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";

interface Props {
  data?: Driver;
}

const validations = yup.object().shape({
  fullName: yup.string().required("Ce champ est obligatoire"),
  email: yup.string().required("Ce champ est obligatoire"),
  phone: yup.string().required("Ce champ est obligatoire"),
  birthday: yup.mixed().required("Ce champ est obligatoire"),
  cityId: yup.mixed().required("Ce champ est obligatoire")
});

type FormInput = {
  fullName: string;
  email: string;
  phone: string;
  birthday: any;
  cityId: any;
};

export default function GeneralInfoForm(props: Props) {
  const { data } = props;
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm<FormInput>({
    resolver: yupResolver(validations)
  });

  useEffect(() => {
    const defaultValues = {
      fullName: data?.fullName,
      email: data?.email,
      phone: data?.phone,
      birthday: data?.birthday,
      cityId: data?.city
    };
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        if (value) {
          if (key === "birthday") {
            setValue(
              key as any,
              new Date(value as string).toISOString().split("T")[0]
            );
          } else {
            setValue(key as any, value);
          }
        }
      });
    }
  }, [data]);

  const updateProfile = useMutation((payload: FormInput) => {
    return apiService.MakePutRequest(
      `users/${data?.id}/driver`,
      payload,
      token
    );
  });

  const updateProfileHandler = async (data: FormInput) => {
    try {
      await updateProfile.mutateAsync({ ...data, cityId: data?.cityId?.id });
      toastSuccess(general.fr.message.profileUpdated);
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  const cityError = errors?.fullName?.message;

  return (
    <section>
      <div className="py-3 d-flex flex-column align-items-center bg-white px-2 mx-auto">
        <form className="col-md-4 form">
          <div className="d-flex flex-column justify-content-center  py-2 align-items-center">
            <div className="position-relative">
              <img
                src={
                  (data?.profilePicture as string) ??
                  `https://ui-avatars.com/api/?name=${data?.fullName}`
                }
                alt=""
                width={100}
                height={100}
                className="rounded-circle border border-2 border-danger overflow-hidden bg-white "
              />
            </div>
          </div>
        </form>
        <form
          onSubmit={handleSubmit(updateProfileHandler)}
          className="col-md-10 mt-2"
        >
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="fullName" className="d-block text-sm">
                Nom complet
              </label>
              <Input
                type="text"
                className="custom-input w-100 mt-1"
                name="fullName"
                placeholder="Nom complet"
                id="fullName"
                // defaultValue={data?.fullName}
                control={control}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="email" className="d-block text-sm">
                Email
              </label>
              <Input
                type="email"
                className="custom-input w-100 mt-1"
                name="email"
                placeholder="Email"
                id="email"
                // defaultValue={data?.email}
                control={control}
                disabled
                readOnly
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="phone" className="d-block text-sm">
                Téléphone
              </label>
              <Input
                className="custom-input w-100 mt-1"
                name="phone"
                placeholder="Téléphone"
                id="phone"
                // defaultValue={data?.phone}
                control={control}
                readOnly
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="birthday" className="d-block text-sm">
                Date de naissance
              </label>
              <Input
                type="date"
                className="custom-input"
                placeholder="Date"
                id="date"
                name="birthday"
                // defaultValue={
                //   data?.birthday
                //     ? new Date(data?.birthday).toISOString().split("T")[0]
                //     : ""
                // }
                control={control}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="cityId" className="d-block text-sm">
                Ville
              </label>
              <Controller
                name="cityId"
                control={control}
                render={({ field }) => (
                  <FormSelectAPI
                    // name="City"
                    id="cityId"
                    isClearable={false}
                    getOptionLabel={(option: City) => option?.label}
                    getOptionValue={(option: City) => option.id}
                    isOptionSelected={(option: City, selectedValue: City[]) => {
                      const isSelected = option?.id === selectedValue?.[0]?.id;
                      return isSelected;
                    }}
                    url={{
                      path: "cities"
                    }}
                    {...field}
                    // defaultValue={data?.city}
                    required
                  />
                )}
              />
              {cityError && <p className="text-red">{cityError}</p>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="d-block text-sm">Téléphone verifié</label>
              <p className="text mt-3 ms-3 text-sm text-secondary ">
                {data?.isVerifiedPhone ? "Oui" : "Non"}
              </p>
            </div>
          </div>
          <button
            disabled={updateProfile.isLoading}
            className="btn btn-md btn-danger rounded-sm my-2 text-white"
          >
            Mettre &agrave; jour les informations
          </button>
        </form>
      </div>
    </section>
  );
}
