import {
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useRides } from "../../hooks/useRides";
import { DELETE_ACTIONS } from "../../config/constants";
import { colors } from "../../config/theme";
import PromptModal from "../../components/modals/PromptModal";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";
import { RideStatus, RideStatusLabels } from "../../helpers/enums";
import { Link } from "react-router-dom";
import { formatDateString } from "../../helpers/general";

interface Props {
  riders: Ride[];
  onRiderSelect: React.Dispatch<BulkDeleteActions>;
}

interface TableBodyProps {
  ride: Ride;
  deletedList: React.Dispatch<BulkDeleteActions>;
}

const ONE_KILOMETER = 1000;
function metersToKilometers(meters: number): number {
  return meters < ONE_KILOMETER ? meters : meters / ONE_KILOMETER;
}

function TableBodyRowContent({ ride, deletedList }: TableBodyProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { removeRide } = useRides();

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: ride.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: ride.id });
    }
  }, [deletedList, isChecked, ride.id]);

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 text-sm">
        {formatDateString(ride.createdAt)}
      </CTableDataCell>
      <CTableDataCell className="px-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          {/* <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          /> */}
          {ride?.driver && (
            <>
              <img
                src={
                  ride?.driver?.profilePicture ??
                  `https://ui-avatars.com/api/?name=${ride?.driver?.fullName}`
                }
                alt=""
                width={30}
                height={30}
                style={{ borderRadius: "100%" }}
              />

              <p className="m-0">{ride?.driver?.fullName}</p>
            </>
          )}
        </div>
      </CTableDataCell>
      <CTableDataCell className="px-3 text-sm">
        {ride?.user && (
          <div className="d-flex align-items-center gap-3">
            <img
              src={
                ride?.user?.profilePicture ??
                `https://ui-avatars.com/api/?name=${ride?.user?.fullName}`
              }
              alt=""
              width={30}
              height={30}
              style={{ borderRadius: "100%" }}
            />

            <div>
              <p className="m-0">{ride.user?.fullName}</p>
            </div>
          </div>
        )}
      </CTableDataCell>

      <CTableDataCell
        className="p-3 w-75 text-sm"
        style={{
          // maxWidth: "200px",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "wrap"
        }}
      >
        {ride.pickupLocationName}
      </CTableDataCell>
      <CTableDataCell
        className="p-3 w-75 text-sm"
        style={{
          // maxWidth: "200px",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "wrap"
        }}
      >
        {ride.destinationLocationName}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {RideStatusLabels[ride.status as keyof typeof RideStatusLabels]}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {ride.paymentMethod}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {metersToKilometers(ride.distance).toFixed(2)}{" "}
        {ride.distance < ONE_KILOMETER ? "m" : "Km"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {ride.estimatedDuration ? Math.floor(ride.estimatedDuration / 60) : ""}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">{ride.fare}</CTableDataCell>
      <CTableDataCell className="p-3 text-sm  align-items-center justify-content-center">
        <Link to={`/rides/${ride?.id}/conversations`} className="text-black">
          <ChatBubbleLeftRightIcon width="25" height="25" />
        </Link>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeRide(ride?.id)}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

export default function RidersTable(props: Props) {
  const { riders, onRiderSelect } = props;
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Date du trajet
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Chauffeur
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Client
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Localisation
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Destination
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Statut
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm ">
              <div className="d-flex align-items-center justify-content-between">
                Moyen de paiement
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Distance
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Durée estimée (min)
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Tarif (MAD)
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {riders?.map(ride => (
            <TableBodyRowContent
              key={ride.id}
              ride={ride}
              deletedList={onRiderSelect}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
