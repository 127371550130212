import { useMemo, useReducer } from "react";
import {
  CFormCheck,
  CModal,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CSpinner,
  CModalBody
} from "@coreui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import EditIcon from "../../components/icons/EditIcon";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { DELETE_ACTIONS } from "../../config/constants";
import PromptModal from "../../components/modals/PromptModal";
import { general } from "../../locales/general";
import RenderTable from "../../components/RenderTable";
import NewSubCategory from "./NewSubCategory";
import { usePaginatedContent } from "../../hooks/usePaginatedContent";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import Pagination from "../../components/Pagination";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { useQueryClient, useMutation } from "react-query";
import apiService from "../../service/apiService";
import { useServiceSubCategoriesQuery } from "../../hooks/ServiceCategories/useSubServiceCategories";
import { FormSelectAPI } from "../../components/FormReactSelect";
import { Link } from "react-router-dom";
import TranslateIcon from "../../components/icons/TranslateIcon";
import { useAuth } from "../../hooks/useAuth";

const ServiceCategoriesSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 93 89"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.4746 0C1.1035 0 0 1.0646 0 2.3867V19.6597C1e-07 20.9818 1.1035 22.0464 2.4746 22.0464H19.5706C20.9417 22.0464 22.0472 20.9818 22.0472 19.6597V2.3867C22.0472 1.0646 20.9417 0 19.5706 0H2.4746ZM33.4026 0.00976562C32.0315 0.00976562 30.928 1.07437 30.928 2.39647V19.6605C30.928 20.9826 32.0315 22.0472 33.4026 22.0472H90.1426C91.5137 22.0472 92.6172 20.9826 92.6172 19.6605V2.39647C92.6172 1.07437 91.5137 0.00976562 90.1426 0.00976562H33.4026ZM2.4746 32.9998C1.1035 32.9998 0 34.0644 0 35.3865V52.6595C1e-07 53.9816 1.1035 55.0462 2.4746 55.0462H19.5706C20.9417 55.0462 22.0472 53.9816 22.0472 52.6595V35.3865C22.0472 34.0644 20.9417 32.9998 19.5706 32.9998H2.4746ZM33.4026 33.0095C32.0315 33.0095 30.928 34.0741 30.928 35.3962V52.6602C30.928 53.9823 32.0315 55.0469 33.4026 55.0469H90.1426C91.5137 55.0469 92.6172 53.9823 92.6172 52.6602V35.3962C92.6172 34.0741 91.5137 33.0095 90.1426 33.0095H33.4026ZM2.4746 65.9995C1.1035 65.9995 0 67.0641 0 68.3862V85.6592C1e-07 86.9813 1.1035 88.0459 2.4746 88.0459H19.5706C20.9417 88.0459 22.0472 86.9813 22.0472 85.6592V68.3862C22.0472 67.0641 20.9417 65.9995 19.5706 65.9995H2.4746ZM33.4026 66.0093C32.0315 66.0093 30.928 67.0739 30.928 68.396V85.66C30.928 86.9821 32.0315 88.0467 33.4026 88.0467H90.1426C91.5137 88.0467 92.6172 86.9821 92.6172 85.66V68.396C92.6172 67.0739 91.5137 66.0093 90.1426 66.0093H33.4026Z" />
    </svg>
  );
};

interface newCategoryModalProps {
  open: boolean;
  onClose: () => void;
  subCategory: ServiceSubCategory;
  id?: string;
}
interface updateCategoryProp {
  label: string;
  categoryId: string;
}

const CategoryModal = ({
  open,
  onClose,
  subCategory,
  id = ""
}: newCategoryModalProps) => {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const [CategoryName, setCategoryName] = useState(subCategory?.label);
  const [selectedCategory, setSelectedCategory] = useState<ServiceCategory>(
    subCategory.category
  );
  const queryClient = useQueryClient();

  const updateCategory = useMutation((data: updateCategoryProp) => {
    return apiService.MakePutRequest(
      `service-categories/sub-categories/${id}`,
      data,
      token
    );
  });

  const updateType = () => {
    updateCategory.mutate(
      { label: CategoryName!, categoryId: selectedCategory?.id! },
      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          queryClient.invalidateQueries("getAllServiceSubCategories");
          toastSuccess(general.fr.message.categoryUpdated);
          onClose();
        }
      }
    );
  };
  const inputInvalid =
    CategoryName === subCategory?.label &&
    selectedCategory.id === subCategory.category.id;

  return (
    <CModal visible={open} onClose={onClose} alignment="center">
      <CModalBody>
        <h5 className="fs-5 mb-4 border-bottom pb-1">
          Mettre à jour la sous-catégories
        </h5>
        <div className="mb-3">
          <label htmlFor="label">Nom de la sous-catégories</label>
          <input
            type="text"
            className="custom-input w-100 mt-1"
            name="label"
            placeholder="Nom de la sous-catégories"
            defaultValue={CategoryName}
            onChange={e => setCategoryName(e.target.value)}
          />
        </div>

        <div className="col-12 mb-3">
          <label htmlFor="Category" className="text-sm d-block mb-2">
            Catégorie
          </label>
          <FormSelectAPI
            name="Category"
            id="Category"
            isClearable={false}
            getOptionLabel={(option: ServiceCategory) => option?.label}
            getOptionValue={(option: ServiceCategory) => option.id}
            isOptionSelected={(
              option: ServiceCategory,
              selectedValue: ServiceCategory[]
            ) => {
              const isSelected = option?.id === selectedCategory?.id;
              return isSelected;
            }}
            url={{
              path: "service-categories"
            }}
            onChange={(category: ServiceCategory) => {
              setSelectedCategory(category);
            }}
            defaultValue={selectedCategory}
            required
          />
        </div>

        <button
          type="button"
          onClick={updateType}
          disabled={updateCategory.isLoading || inputInvalid}
          className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-100 mt-2 p-2 fw-bold text-white"
        >
          {updateCategory.isLoading ? (
            <>
              <CSpinner size="sm" /> {general.fr.wait}
            </>
          ) : (
            "Enregistrer"
          )}
        </button>
      </CModalBody>
    </CModal>
  );
};

function TableBodyRowContent({
  deleteServiceCategory,
  subCategory,
  deleteList
}: {
  deleteServiceCategory : (id: string) => void;
  subCategory: ServiceSubCategory;
  deleteList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { isAdmin } = useAuth();

  useEffect(() => {
    if (isChecked) {
      deleteList({ type: DELETE_ACTIONS.ADD, id: subCategory?.id });
    } else {
      deleteList({ type: DELETE_ACTIONS.REMOVE, id: subCategory?.id });
    }
  }, [deleteList, isChecked, subCategory?.id]);

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 text-sm">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
          className="!ms-2"
        />
        <span className="ms-2"> {subCategory.label}</span>
      </CTableDataCell>

      <CTableDataCell className="p-3 text-sm">
        {subCategory?.category?.label ?? "-"}
      </CTableDataCell>

      <CTableDataCell>
        <div className="d-flex align-items-center mt-2">
          <button
            onClick={() => setShowEditModal(true)}
            className="outline-none border-0 bg-transparent"
          >
            <EditIcon width="18" height="18" fill={colors.secondary} />
          </button>

          {isAdmin && (
            <Link
              to={"/service-sub-category-translations/new"}
              state={{ category: subCategory }}
              className="me-4 ms-2"
            >
              <TranslateIcon width="21" height="21" fill={"#000"} />
            </Link>
          )}

          <button
            onClick={() => setShowModal(true)}
            className="outline-none border-0 bg-transparent"
          >
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          </button>
          
        </div>
      </CTableDataCell>
      <PromptModal
        onClose={() => setShowModal(false)}
        onAccept={() => deleteServiceCategory(subCategory?.id)}
        open={showModal}
      />

      <CategoryModal
        onClose={() => setShowEditModal(false)}
        open={showEditModal}
        id={subCategory?.id}
        subCategory={subCategory}
      />
    </CTableRow>
  );
}
interface TableProps {
  deleteServiceCategory: (id: string) => void;
  subCategories: ServiceSubCategory[];
  categoriesToBeDeletedList: React.Dispatch<BulkDeleteActions>;
}
function CategoriesTable({
  deleteServiceCategory,
  categoriesToBeDeletedList,
  subCategories
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm w-50">
              Nom de la sous-catégorie
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm w-50">
              Nom de la catégorie
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {subCategories?.map(subCategory => (
            <TableBodyRowContent
              key={subCategory?.id}
              subCategory={subCategory}
              deleteList={categoriesToBeDeletedList}
              deleteServiceCategory={deleteServiceCategory}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}

const PAGE_SIZE = 10;

export default function ServiceCategories() {
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const {
    categories,
    isLoading,
    deleteServiceCategory,
    mutating
  } = useServiceSubCategoriesQuery();

  const filteredcategories = useMemo(() => {
    const results = categories?.filter((category: any) =>
      category.label?.toLowerCase()?.includes(searchQuery)
    );
    return results;
  }, [categories, searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredcategories?.length / PAGE_SIZE));
  }, [searchQuery, filteredcategories]);

  const paginatedContent = usePaginatedContent(
    currentPage,
    PAGE_SIZE,
    filteredcategories!
  );

  const [categoriesToDelete, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    categoriesToDelete.ids.forEach(id => {
      if (!id) return;
      deleteServiceCategory(id);
    });
  };

  return (
    <div className="w-100 bg-white rounded-lg rounded-bottom">
      <div className="d-flex justify-content-end">
        <div
          className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3 w-100"
          style={{ borderTopRightRadius: "8px" }}
        >
          <ServiceCategoriesSVG />
          <p className="fs-5 fw-bold m-0 text-white">
            {categories?.length}{" "}
            {categories?.length > 1 ? "Sous-catégories" : "Sous-catégorie"}
          </p>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row  align-items-center justify-content-between gap-2 p-3">
        <div className="d-flex flex-column flex-md-row gap-2">
          <button
            onClick={() => setVisible(!visible)}
            className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
          >
            + Ajouter Catégories
          </button>
          <CModal
            alignment="center"
            visible={visible}
            onClose={() => setVisible(false)}
            backdrop="static"
          >
            <NewSubCategory
              onAccept={() => {}}
              onClose={() => setVisible(false)}
            />
          </CModal>

          <button
            disabled={mutating || categoriesToDelete?.ids.length === 0}
            onClick={handleBulkDelete}
            className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
          >
            {!mutating ? (
              <>
                <DeleteIcon fill={colors.primary} /> Supprimer Catégories
              </>
            ) : (
              <>
                <CSpinner size="sm" /> {general.fr.wait}
              </>
            )}
          </button>
        </div>

        <div className="search-form">
          <div className=" input-field">
            <MagnifyingGlassIcon color="gray" width={20} />
            <input
              type="text"
              className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
              placeholder="Rechercher"
              onChange={e => setSearchQuery(e?.target?.value?.toLowerCase())}
            />
          </div>
        </div>
      </div>
      <RenderTable
        loading={isLoading}
        render={() =>
          paginatedContent?.length > 0 ? (
            <CategoriesTable
              subCategories={paginatedContent as ServiceSubCategory[]}
              categoriesToBeDeletedList={dispatch}
              deleteServiceCategory={deleteServiceCategory}
            />
          ) : (
            <p className="text-center fs-5 my-2">{general.fr.noData}</p>
          )
        }
      />
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={1}
        totalCount={totalPages}
        pageSize={PAGE_SIZE}
      />
    </div>
  );
}
