import { useQuery } from "react-query";
import { apiConfig } from "../config/apiConfig";
import apiService from "../service/apiService";
import { useCookie } from "./useCookie";

export function useRideChats(rideId: string) {
  const endpoint = apiConfig.rides.chats(rideId);
  const { token } = useCookie("vToken");
  const deps = [endpoint, rideId, token];

  const { data, isLoading } = useQuery<RideConversation[]>(deps, () =>
    apiService.MakeGetRequest(endpoint, token)
  );

  return {
    chats: data ?? [],
    loadingConversation: isLoading
  };
}
