import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React, { useReducer } from "react";
import BoxWrapper from "../../components/BoxWrapper";
import { SidebarIcon } from "../../components/icons/SidebarIcons";
import { pluralize } from "../../helpers/general";
import { general } from "../../locales/general";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import TransactionsTable from "./TransactionsTable";
import { useTransactions } from "../../hooks/useTransactions";
import {
  TransactionStatus,
  TransactionStatusLabel,
  TransactionType,
  TransactionTypeLabel,
  TranscationPaymentMethods
} from "../../helpers/enums";
import RestrictedRoute from "../auth/RestrictedRoute";

const PAGE_SIZE = 10;
export default function TransactionsPage() {
  const {
    queries,
    transactions,
    isfetchingTransaction: isLoading,
    addQuery
  } = useTransactions();

  const [transactionsToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  return (
    <RestrictedRoute>
      <h5 className="fs-4 fw-bold mb-2">Transactions</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-end align-items-baseline ps-2">
          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3 "
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <SidebarIcon.ArrowsRightLeftIcon className="fill-white w-5 h-5" />
            <p className="fs-5 fw-bold m-0 text-white text-pre">
              {transactions?.meta?.totalItems}{" "}
              {pluralize("transaction", transactions?.meta?.totalItems)}
            </p>
          </div>
        </div>
        <div className="transactions-filters p-3">
          <div className="filters gap-2">
            <div>
              <label htmlFor="status">Statut:</label>
              <select
                name="status"
                id="status"
                value={queries.status}
                onChange={e => addQuery("status", e.target.value as any)}
              >
                <option value="">Tous</option>
                {Object.values(TransactionStatus).map(status => (
                  <option key={status} value={status}>
                    {
                      TransactionStatusLabel[
                        status as keyof typeof TransactionStatusLabel
                      ]
                    }
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="type">Type:</label>
              <select
                name="type"
                id="type"
                value={queries.type}
                onChange={e => addQuery("type", e.target.value as any)}
              >
                <option value="">Tous</option>
                {Object.values(TransactionType).map(type => (
                  <option key={type} value={type}>
                    {
                      TransactionTypeLabel[
                        type as keyof typeof TransactionTypeLabel
                      ]
                    }
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="paymentMethod">Méthode de paiement:</label>
              <select
                name="paymentMethod"
                id="type"
                value={queries.paymentMethod}
                onChange={e => addQuery("paymentMethod", e.target.value as any)}
              >
                <option value="">Tous</option>
                {Object.values(TranscationPaymentMethods).map(method => (
                  <option key={method} value={method}>
                    {method}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="search-form mt-3 ms-2">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e =>
                  addQuery("query", e?.target?.value?.toLowerCase())
                }
                defaultValue={queries.query}
              />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <RenderTable
            loading={isLoading}
            render={() =>
              transactions?.items && transactions?.items?.length > 0 ? (
                <TransactionsTable
                  transactions={transactions?.items ?? []}
                  onTransactionSelect={dispatch}
                />
              ) : (
                <p className="text-center fs-5 my-2">{general.fr.noData}</p>
              )
            }
          />
        </div>
      </BoxWrapper>
      <Pagination
        currentPage={queries.page}
        onPageChange={page => {
          addQuery("page", page);
        }}
        siblingCount={1}
        totalCount={transactions?.meta?.totalPages ?? 1}
        pageSize={PAGE_SIZE}
      />
    </RestrictedRoute>
  );
}
